import $ from "jquery";

(function() {
  /**
   * Return Book components consistently.
   * @param obj book - the book title
   * @returns obj
   */
  const getBookComponents = function(book) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const datePattern = /(\d{2})(\d{2})(\d{2})/;

    const noFileType = book.replace(/.md/g, '');
    const date = noFileType.match(/\[([^)]+)\]/);
    const noDate = noFileType.replace(/\[[^)]*\]/g, '');

    if (!date || date.length < 1) {
      return null;
    }

    return {
      title: noDate,
      sortOn: date[1],
      date: new Date(date[1].replace(datePattern,'20$1-$2-$3')).toLocaleDateString('en-US', options),
    };
  }

  $.getJSON('https://gist.githubusercontent.com/mcnamee/5b117b94879833aadeaee0c3ebca8858/raw/recent-books.json')
    .done(function(data) {
      let books = [];

      $.each(data, function(i, item) {
        const book = getBookComponents(item);

        if (book) {
          books.push(book);
        }
      });

      // Sort Records by date
      books.sort((a, b) => (a.sortOn > b.sortOn) ? -1 : 1);

      // Output card.
      $.each(books, function(i, item) {
        // Only output 16 records
        if ( i === 16 ) {
          return false;
        }

        return $('[data-books-feed]').append(`<div class="col-md-3 mb-5"><article class="card"><span class="card-title">${item.title}</span><time datetime="${item.date}" class="eyebrow text-muted">${item.date}</time></article></div>`);
      });
    });
})();
